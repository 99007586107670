<template>
    <v-row
        justify="center"
        class="mt-8"
    >
        <v-col
            cols="12"
            md="4"
            xl="4"
            sm="6"
        >
            <v-card>
                <v-card-title
                    class="indigo white--text"
                >
                    <h3>Nova Senha</h3>
                </v-card-title>
                <v-card-text class="pt-4" >
                    <v-form>
                        <v-text-field
                            v-model="form.senha"
                            label="Senha"
                            prepend-inner-icon="mdi-key"
                            maxlength="16"
                            hint="Pelo menos 8 caracteres"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            :rules="[
                                form_senha_rules.required, form_senha_rules.min
                            ]"
                        ></v-text-field>
                        <v-text-field
                            v-model="form.confirmaSenha"
                            label="Confirmar Senha"
                            prepend-inner-icon="mdi-key"
                            type="password"
                            maxlength="16"
                            hint="Pelo menos 8 caracteres"
                            :rules="[
                                form_confirmaSenha_rules.required, form_confirmaSenha_rules.min
                            ]"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="indigo"
                        dark
                        block
                    >
                        Comfirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            form: {
                senha: '',
                confirmaSenha: ''
            },
            form_senha_rules: {
                required: value => !!value || 'Senha é obrigatória!',
                min: v => v.length >= 8 || 'Mínimo de 8 caracteres!'
            },
            form_confirmaSenha_rules: {
                required: value => !!value || 'Senha é obrigatória!',
                min: v => v.length >= 8 || 'Mínimo de 8 caracteres!'
            },
            show1: false
        }
    }
}
</script>